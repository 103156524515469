<template>
    <ul class="chapter-index">
        <!-- <li class="chapter-index-item">
            <div class="chapter-index-box">
            <div class="chapter-index-body">
                <i class="el-icon-file-zip"></i>
                <h2 class="chapter-index-title">多章节上传</h2>
                <p class="chapter-index-tips-text">支持扩展名：.rar .zip .7z格式压缩文件</p>
            </div>
            </div>
            <div class="chapter-index-button chapter-index-tac">
                <el-upload
                    class="batch-upload"
                    ref="upload"
                    accept=".rar,.zip,.7z"
                    name="file"
                    :disabled="isBatchUploading || isBatchUploadError"
                    :data="{
                        name: currentFile.originFile,
                        token: tokenData.token,
                        key: tokenData.key + tokenData.fileName
                    }"
                    :multiple="false"
                    :action="currentFile.uploadHost || '#'"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="batchUpload"
                    :on-progress="progressHandle"
                    :on-error="uploadError"
                    :on-success="uploadSuccess"
                >
                    <el-button type="primary" size="mini" @click="batchUploadClick">{{batchUploadMsg}}</el-button>
                </el-upload>
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="click">
                    <div>
                        1、压缩包样式：压缩包中包含一个或者数个文件夹，不可一个大文件夹包含众多子文件夹。<br />
                        2、章节压缩包规范，支持rar、zip、7z格式压缩包。<br />
                        3、文件夹命名：章节数字编号，章节数字编号为2位数（例如：01）不可从0开始。<br />
                        4、文件夹中，图片按顺序依次编号，为三位数，例如：001。不可从000开始。<br />
                        5、每一个压缩包限制格式，最大限制500MB
                    </div>
                    <el-button slot="reference" type="text" size="mini" class="tip-icon"><i class="el-icon-question"></i></el-button>
                </el-popover>
            </div>
            <ul class="upload-list">
                <li class="flex-row upload-item" v-for="item in uploadList" :key="item.originFile">
                    <div><i class="el-icon-pin"></i></div>
                    <div class="color-green ml10 mr10">{{item.originFile}}</div>
                    <el-progress :percentage="item.progress" :status="item.uploadStatus"></el-progress>
                    <div class="upload-item-msg mr10">
                        {{item.message}}
                        <span v-if="item.decompressStatus === 'error'" class="color-red cursor-pointer mr10" type="text" size="mini" @click="decompressChapter(item)">继续解压</span>
                    </div>
                    <div class="upload-item-btns">
                        <el-button v-if="item.decompressStatus === 'success'" type="primary" size="mini" @click="gotoBatchEdit(item)">去编辑</el-button>
                        <el-button v-if="['success', 'error'].includes(item.decompressStatus)" type="warning" size="mini" @click="removeDecompressChapter(item)">删除</el-button>
                    </div>
                </li>
            </ul>
        </li> -->
        <li class="chapter-index-item">
            <div class="chapter-index-box">
            <div class="chapter-index-body">
                <i class="el-icon-file-add"></i>
                <h2 class="chapter-index-title">单章节上传</h2>
                <p class="chapter-index-tips-text">单独章节进行编辑、多图片上传</p>
            </div>
            </div>
            <div class="chapter-index-button chapter-index-tac">
                <el-button type="primary" size="mini" @click="singleUpload">立即进入</el-button>
            </div>
        </li>
    </ul>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-17 19:28:16
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-13 14:47:00
 * @Description: 新增章节
 * @Company: 成都二次元动漫
 */
import { uploadMixin, dataMixin } from '@/mixins';
const DECOMPRESS_PROGRESS_DELAY = 500; // 解压进度轮询延迟
export default {
    mixins: [uploadMixin, dataMixin],
    props: {
        active: Number
    },
    data() {
        return {
            currentFileName: '',
            batchUploadMsg: '立即添加',
            decompressFileCount: 0,
            hasDecompressedChapter: false,
            currentFile: {
                originFile: '',
                uploadFile: '',
                progress: 0,
                uploadStatus: '',
                decompressStatus: '',
                message: '',
                uploadHost: null
            },
            uploadList: [], // 上传完成的压缩文件列表
            chapterList: []
        };
    },
    computed: {
        isBatchUploading() {
            return this.currentFile.uploadStatus === 'loading' || this.currentFile.decompressStatus === 'loading';
        },
        isBatchUploadError() {
            let isError = false;
            if (this.currentFile.uploadStatus === 'exception' || this.currentFile.decompressStatus === 'error') {
                isError = true;
            } else {
                this.uploadList.forEach(item => {
                    if (item.decompressStatus === 'error') isError = true;
                });
            }
            return isError;
        }
    },
    watch: {
        chapterList(newList) {
            this.uploadList = newList.reduce((arr, item) => {
                const hasList = arr.some(i => i.originFile === item.originFile);
                if (!hasList) {
                    arr.push({
                        originFile: item.originFile,
                        uploadFile: item.uploadFile,
                        progress: 100,
                        uploadStatus: 'success',
                        decompressStatus: 'success',
                        message: ''
                    });
                }
                return arr;
            }, []);
            this.hasDecompressedChapter = this.uploadList.length ? true : false;
        }
    },
    beforeCreate() {
        const { commit, state } = this.$store;
        if (state.app.breadcrumb[2].name === 'addComic') state.app.breadcrumb.splice(2, 1);
        commit('app/setBreadcrumb', [...state.app.breadcrumb]);
        this.$emit('update:active', 1);
    },
    created() {
        this.singleUpload();
        // this.checkDecompressChapter();
    },
    methods: {
        // 检查是否有未处理的解压章节
        async checkDecompressChapter() {
            await this.$api('getDecompressChapter', { comicId: this.$route.query.comicId }).then(({ data: { rows = [] } }) => {
                this.chapterList = rows;
                if (rows.length) {
                    this.batchUploadMsg = '继续添加';
                    this.$message.warning('你有未编辑的章节');
                }
            });
        },
        // 批量处理
        gotoBatchEdit({ originFile }) {
            const { comicId, comicName, authorName, uploaderUid } = this.$route.query;
            this.$router.push({ name: 'editComicChapter', query: { type: 'add', comicName, comicId, uploaderUid, authorName, originFile } });
        },
        // 批量上传button点击事件
        batchUploadClick() {
            if (this.isBatchUploadError) this.$message.error('请保证已正确上传压缩文件或者删除该压缩文件');
        },
        // 删除解压章节
        removeDecompressChapter({ originFile }) {
            this.$utils.confirm('', '确定删除未编辑章节吗？', '删除中...', (action, instance, done) => {
                return this.$api('removeDecompressChapter', { comicId: this.$route.query.comicId, originFile }).then(res => {
                    this.resetDataField('currentFile');
                    this.checkDecompressChapter();
                });
            });
        },
        // 上传文件方法
        uploadFileHandle(blob, name) {
            this.uploadHandle({ file: blob, name }, { onUploadProgress: this.uploadProgressHandle }).then(({ key }) => {
                this.uploadSuccess();
            }).catch(() => this.uploadError());
        },
        // 上传进度
        uploadProgressHandle({ loaded, total }) {
            this.currentFile.progress = parseInt((loaded / total) * 100).toFixed(0) - 0;
        },
        // 批量上传选择事件
        batchUpload(file) {
            const isLt500M = file.size / 1024 / 1024 < 500;
            if (this.currentFile.originFile === file.name && ['success', 'loading', 'exception'].includes(this.currentFile.uploadStatus)) return;
            if (this.uploadList.find(i => i.originFile === file.name)) return this.$message.error('上传压缩包不能重名');
            if (!/(\.zip|\.7z|\.rar)$/.test(file.name)) return this.$message.error('格式错误');
            if (!isLt500M) return this.$message.error('请上传小于500MB的文件');

            const { comicId, uploaderUid } = this.$route.query;
            this.decompressFileToken(comicId, file.name, uploaderUid).then(res => {
                this.currentFile.originFile = file.name;
                this.currentFile.uploadStatus = 'loading';
                this.uploadList.push(this.currentFile);
                this.uploadFileHandle(file.raw, file.name);
                // this.$refs.upload.submit();
            });
        },
        // 上传失败
        uploadError() {
            this.currentFile.uploadStatus = 'exception';
            this.$message.error('上传失败');
            this.uploadEnd();
        },
        // 上传成功
        uploadSuccess() {
            this.currentFile.uploadStatus = 'success';
            this.currentFile.uploadFile = `/${this.tokenData.key + this.tokenData.fileName}`;
            this.decompressChapter(this.currentFile);
            this.uploadEnd();
        },
        // 批量上传完成
        uploadEnd() {
            this.$refs.upload.clearFiles();
            this.currentFileName = '继续添加';
        },
        // 提交解压
        decompressChapter({ originFile, uploadFile }) {
            const { comicId, comicName, authorName } = this.$route.query;
            return this.$api('decompressChapter', { comicId, comicName, authorName, originFile, uploadFile }).then(res => {
                this.currentFile.message = '解压中...';
                this.currentFile.decompressStatus = 'loading';
                this.loopDecompressProgress();
            });
        },
        // 获取解压进度
        loopDecompressProgress() {
            const { comicId } = this.$route.query;
            return this.$api('getDecompressProgress', { comicId, originFile: this.currentFile.originFile }).then(res => {
                this.decompressFileCount++;
                const { status, desc } = res.data;
                if (status === 5) {
                    this.currentFile.message = '';
                    this.currentFile.decompressStatus = 'success';
                    this.$message.success({
                        message: '解压完成!',
                        duration: 2000
                    });
                    this.resetDataField('currentFile');
                    return ;
                } else if (status === 9) { // 解压异常，先删除在提示重新上传
                    this.$api('removeDecompressChapter', { comicId: this.$route.query.comicId, originFile: this.currentFile.originFile }).then(res => {
                        this.currentFile.message = '解压失败';
                        this.currentFile.decompressStatus = 'error';
                        this.$message.error({
                            message: desc,
                            uration: 1200
                        });
                        this.resetDataField('currentFile');
                    });
                    return;
                }
                if (this.decompressFileCount === 30) {
                    this.$message.error('解压超时');
                    this.currentFile.message = '解压失败';
                    this.currentFile.decompressStatus = 'success';
                    this.resetDataField('currentFile');
                    return ;
                }
                setTimeout(() => this.loopDecompressProgress(), DECOMPRESS_PROGRESS_DELAY);
            }).catch(e => {
                this.currentFile.decompressStatus = 'error';
            });
        },
        // 单章上传
        singleUpload() {
            this.$emit('update:active', 2);
            this.$router.replace({ name: 'editChapter', query: { ...this.$route.query, single: 1 } });
        },
        // 上传进度
        progressHandle(event) {
            this.currentFile.progress = event.percent;
        }
    }
};
</script>

<style lang="scss">
    @import "../../../../styles/var";
    .chapter-index{
        display: flex;
        justify-content: center;
        &-item {
            width: 40%;
            min-width: 200px;
        }
        &-box {
            position: relative;
            height: 160px;
            border: $border-width-base dotted $color-primary;
            margin: 20px;
            border-radius: $border-radius-base;
            &:hover {
            opacity: 0.75;
            }
        }
        &-body {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            i {
            font-size: 60px;
            margin-bottom: 20px;
            color: $color-primary;
            }
        }
        &-button {
            margin: 20px;
            .el-upload {
            display: block;
            }
        }
        &-title {
            font-size: 24px;
            margin-bottom: 5px;
            font-weight: normal;
            color: #666;
        }
        &-tips-text {
            font-size: 14px;
            color: #999;
        }
        &-tac{
            text-align: center;
        }
        .batch-upload{
            display: inline-block;
            border: none;
        }
        .tip-icon{
            vertical-align: middle;
            i{
                font-size: 18px;
            }
        }
        .upload-list{
            list-style-type: none;
            padding: 0 0 15px;
        }
        .upload-item{
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            padding: 5px 0;
            .el-progress{
                flex-grow: 1;
            }
        }
    }
</style>
